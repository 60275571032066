import {
  AttendanceStatus,
  AttendanceStatusForDisplay,
  EventRegistrationMode,
  EventType,
  EventTypeValues,
  PermissionTier,
  RegistrationOnlyEventTypes,
  attendanceStatusForDisplayToDisplayName,
  attendanceStatusToDisplayName,
} from 'app/enums'
import {
  MILES_TO_METERS,
  NGPVAN_CONTRIBUTION_FORM_DATA_PREFIX,
} from 'app/constants'
import {createIntl, defineMessages} from 'util/i18n'

import {F} from 'util/i18n'
import {VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP} from 'app/enums'
import {centsToDollars} from 'util/currency'
import cv from './clientVars'
import {eventTypeToDisplayMessage} from 'app/enumMessages'
import flag from 'experiments/flag'
import {isEveryIdentityFieldFilledOut} from 'util/user'
import {orgFlagIsActive} from './organization'
import {remapImageUrlToProxy} from './image'

const messages = defineMessages({
  eventImgAltText: {
    defaultMessage: '{eventName} organized by {orgName}',
  },
})

// Reorder high-priority events like so:
// - Physical that are < 250miles
// - Virtual
// - Physical >= 250 miles
export function sortHighPriorityEvents(eventsHighPriority) {
  const CLOSE_DISTANCE = 250

  const sorted = eventsHighPriority.slice(0).sort((a, b) => {
    if (a.is_virtual && b.is_virtual) {
      return 0
    }

    const aIsClose =
      typeof a.distance === 'number' &&
      a.distance / MILES_TO_METERS < CLOSE_DISTANCE
    const bIsClose =
      typeof b.distance === 'number' &&
      b.distance / MILES_TO_METERS < CLOSE_DISTANCE
    const aIsFar =
      typeof a.distance === 'number' &&
      a.distance / MILES_TO_METERS >= CLOSE_DISTANCE
    const bIsFar =
      typeof b.distance === 'number' &&
      b.distance / MILES_TO_METERS >= CLOSE_DISTANCE
    if (aIsClose && (bIsFar || b.is_virtual)) {
      return -1
    }
    if (bIsClose && (aIsFar || a.is_virtual)) {
      return 1
    }

    if (a.is_virtual && (!b.is_virtual || bIsFar)) {
      return -1
    }
    if (b.is_virtual && (!a.is_virtual || aIsFar)) {
      return 1
    }

    if (typeof a.distance === 'number' && typeof b.distance === 'number') {
      return a.distance - b.distance
    }

    return 0
  })

  return sorted
}

const TYPE_TO_IMAGE_MAP = {
  [EventType.COMMUNITY]: 'community-event',
  [EventType.MEETING]: 'meeting',
  [EventType.PHONE_BANK]: 'phone-bank',
  [EventType.RALLY]: 'rally',
  [EventType.SOLIDARITY_EVENT]: 'solidarity-event',
  [EventType.TEXT_BANK]: 'text-bank',
  [EventType.TOWN_HALL]: 'town-hall',
  [EventType.TRAINING]: 'training',
  [EventType.OTHER]: 'virtual-event',
  [EventType.VOTER_REG]: 'voter-registration',
  [EventType.DEBATE_WATCH_PARTY]: 'watch-party',
}

export function maybeGetPlaceholderImage(eventType) {
  let placeholderUrl, placeholderSrcSet

  const filename = TYPE_TO_IMAGE_MAP[eventType] || 'community-event'

  if (filename) {
    const baseUrl = `${cv.s3_static_hostname}/static/images/event-placeholders/emojis/${filename}`
    const image1x = remapImageUrlToProxy(`${baseUrl}.png`)
    const image2x = remapImageUrlToProxy(`${baseUrl}@2x.png`)
    placeholderUrl = image1x
    placeholderSrcSet = `${image1x}, ${image2x} 2x`
  }

  return {placeholderUrl, placeholderSrcSet}
}

export function eventTypeDisplayNameForCurrentOrg(
  event,
  organization,
  includeBaseNameIfCustom
) {
  const customName = event.custom_event_type_name
  if (
    event.current_org_is_owner_or_co_owner &&
    orgFlagIsActive(
      organization,
      'enable_custom_event_type_name_for_type_other'
    ) &&
    event.event_type === EventType.OTHER &&
    customName
  ) {
    if (includeBaseNameIfCustom) {
      // intentionally don't include `is_virtual` here so we'll say
      // "Other (name)" instead of "Virtual Event (name)"
      return (
        <>
          <F {...eventTypeToDisplayMessage(event.event_type)} /> {customName}
        </>
      )
    }
    return customName
  }
  const alwaysVirtualEventTypes = [EventType.SOCIAL_MEDIA_CAMPAIGN]
  const includeVirtualInDisplayName =
    event.is_virtual &&
    !isRegistrationOnlyEvent(event) &&
    !alwaysVirtualEventTypes.includes(event.event_type)
  return (
    <F
      {...eventTypeToDisplayMessage(
        event.event_type,
        includeVirtualInDisplayName
      )}
    />
  )
}

function getSelectOptionForStatus(status) {
  return {
    text: attendanceStatusToDisplayName[status],
    value: status,
    key: status,
  }
}

function getSelectOptionForStatusForDisplay(status) {
  return {
    text: attendanceStatusForDisplayToDisplayName[status],
    value: status,
    key: status,
  }
}

// N.B.(margot) see comment on AttendanceStatusForDisplay def'n in enums.ts
export function translateAttendanceStatuses(attendanceStatuses) {
  const attendanceStatusesSet = new Set(attendanceStatuses)
  if (attendanceStatusesSet.has(AttendanceStatusForDisplay.UNKNOWN)) {
    attendanceStatusesSet.delete(AttendanceStatusForDisplay.UNKNOWN)
    attendanceStatusesSet.add(AttendanceStatusForDisplay.REGISTERED)
    attendanceStatusesSet.add(AttendanceStatusForDisplay.CONFIRMED)
  }
  return [...attendanceStatusesSet].map(
    (status) => attendanceStatusMapping[status]
  )
}

export const attendanceStatusMapping = {
  [AttendanceStatusForDisplay.REGISTERED]: AttendanceStatus.REGISTERED,
  [AttendanceStatusForDisplay.COMPLETED]: AttendanceStatus.COMPLETED,
  [AttendanceStatusForDisplay.CONFIRMED]: AttendanceStatus.CONFIRMED,
  [AttendanceStatusForDisplay.CANCELLED]: AttendanceStatus.CANCELLED,
  [AttendanceStatusForDisplay.NO_SHOW]: AttendanceStatus.NO_SHOW,
}

export function getAttendanceOptionsForStatusForDisplay(
  includePastEventStatuses
) {
  const statuses = [AttendanceStatusForDisplay.CANCELLED]
  if (includePastEventStatuses) {
    statuses.push(
      AttendanceStatusForDisplay.COMPLETED,
      AttendanceStatusForDisplay.NO_SHOW,
      AttendanceStatusForDisplay.UNKNOWN
    )
  } else {
    statuses.push(
      AttendanceStatusForDisplay.REGISTERED,
      AttendanceStatusForDisplay.CONFIRMED
    )
  }
  return statuses.map(getSelectOptionForStatusForDisplay)
}

export function getParticipationOrAttendanceOptions(includePastEventStatuses) {
  const statuses = [
    AttendanceStatus.CANCELLED,
    AttendanceStatus.REGISTERED,
    AttendanceStatus.CONFIRMED,
  ]
  if (includePastEventStatuses) {
    statuses.push(AttendanceStatus.COMPLETED, AttendanceStatus.NO_SHOW)
  }
  return statuses.map(getSelectOptionForStatus)
}

export function getParticipationOrAttendanceOptionsForGroupEvent() {
  return [AttendanceStatus.CANCELLED, AttendanceStatus.REGISTERED].map(
    getSelectOptionForStatus
  )
}

export function permissionTierIsAdminOrOrganizer(permissionTier) {
  return (
    permissionTier === PermissionTier.ADMIN ||
    permissionTier === PermissionTier.ORGANIZER
  )
}

export function maybeGetEventSubheading(
  maybeFeedId,
  event,
  enablePerEventCoOwnership
) {
  const organizedByVolunteer =
    event.created_by_distributed_organizing &&
    !permissionTierIsAdminOrOrganizer(event.creator_permission_tier)
  const notViewingFromOwningOrg = maybeFeedId !== event.organization.slug
  const totalCoOwningOrgs = event.manually_added_co_owning_orgs?.length

  if (
    enablePerEventCoOwnership &&
    organizedByVolunteer &&
    totalCoOwningOrgs &&
    totalCoOwningOrgs === 1
  ) {
    return (
      <F
        defaultMessage="Volunteer hosted for {hostName}, with {coHostName}"
        values={{
          hostName: event.organization.name,
          coHostName: event.manually_added_co_owning_orgs[0].name,
        }}
      />
    )
  } else if (
    enablePerEventCoOwnership &&
    organizedByVolunteer &&
    totalCoOwningOrgs &&
    totalCoOwningOrgs > 1
  ) {
    return (
      <F
        defaultMessage="Volunteer hosted for {orgName}, with {numCoHosts} others"
        values={{
          orgName: event.organization.name,
          numCoHosts: totalCoOwningOrgs,
        }}
      />
    )
  } else if (
    enablePerEventCoOwnership &&
    totalCoOwningOrgs &&
    totalCoOwningOrgs === 1
  ) {
    return (
      <F
        defaultMessage="Hosted by {orgName}, with {coHostName}"
        values={{
          orgName: event.organization.name,
          coHostName: event.manually_added_co_owning_orgs[0].name,
        }}
      />
    )
  } else if (
    enablePerEventCoOwnership &&
    totalCoOwningOrgs &&
    totalCoOwningOrgs > 1
  ) {
    return (
      <F
        defaultMessage="Hosted by {orgName}, with {numCoHosts} others"
        values={{
          orgName: event.organization.name,
          numCoHosts: totalCoOwningOrgs,
        }}
      />
    )
  } else if (notViewingFromOwningOrg && organizedByVolunteer) {
    return (
      <F
        defaultMessage="Volunteer organized for {orgName}"
        values={{orgName: event.organization.name}}
      />
    )
  } else if (notViewingFromOwningOrg && isRegistrationOnlyEvent(event)) {
    return (
      <F
        defaultMessage="Organized by {orgName}"
        values={{orgName: event.organization.name}}
      />
    )
  } else if (notViewingFromOwningOrg) {
    return (
      <F
        defaultMessage="Hosted by {orgName}"
        values={{orgName: event.organization.name}}
      />
    )
  } else if (organizedByVolunteer) {
    return <F defaultMessage="Volunteer organized" />
  }

  return null
}

export function isVirtual(event) {
  return !!event.is_virtual
}

export function isAdvocacyEvent(event) {
  return event?.event_type === EventType.ADVOCACY_CALL
}

export function isPetitionEvent(event) {
  return event?.event_type === EventType.PETITION
}

export function isDonationCampaignEvent(event) {
  return event?.event_type === EventType.DONATION_CAMPAIGN
}

export function isGroupEvent(event) {
  return event?.event_type === EventType.GROUP
}

// Needed because a HostedEvent is not an EventLike
export function isDonationCampaignHostedEvent(hostedEvent) {
  return hostedEvent.event_type === EventType.DONATION_CAMPAIGN
}
export function isGroupHostedEvent(hostedEvent) {
  return hostedEvent.event_type === EventType.GROUP
}

function isDonateToRsvpEvent(event) {
  return event?.registration_mode === EventRegistrationMode.DONATE_TO_RSVP
}

function isDonateToRsvpOptionalEvent(event) {
  return (
    event?.registration_mode === EventRegistrationMode.DONATE_TO_RSVP_OPTIONAL
  )
}

export function isPromptForDonationEvent(event) {
  return isDonateToRsvpEvent(event) || isDonateToRsvpOptionalEvent(event)
}

export function isCommitmentGatheringEvent(event) {
  // users sign up for events in this category committing themselves to future action
  return (
    event?.event_type === EventType.PLEDGE ||
    event?.event_type === EventType.INTEREST_FORM
  )
}

export function isRegistrationOnlyEvent(event) {
  // For events in this category, "participating" is identical with signing up.
  if (!event?.event_type) {
    return false
  }
  return RegistrationOnlyEventTypes.has(event.event_type)
}

export function isVirtualFlexibleEventThatAllowDashboardSignups(event) {
  if (!event?.event_type) {
    return false
  }
  return VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP.has(
    event.event_type
  )
}
export function getDonationFormUrl(event) {
  const {fundraiser_config_shortcode: eaShortCode} = event
  return eaShortCode
    ? `${NGPVAN_CONTRIBUTION_FORM_DATA_PREFIX}${eaShortCode}`
    : null
}

export function getEventTypeAffinityChecks(event, dashboardOrganization) {
  const donateToRsvpEnabled = dashboardOrganization
    ? orgFlagIsActive(dashboardOrganization, 'enable_donate_to_rsvp')
    : // defaults to true for supporter facing surfaces. we want to preserve
      // donate to rsvp behavior there for existing events even if the org flag
      // gets turned off
      true
  const isDonateToRsvp = donateToRsvpEnabled && isDonateToRsvpEvent(event)
  const isDonateToRsvpOptional =
    donateToRsvpEnabled && isDonateToRsvpOptionalEvent(event)
  const isPromptForDonation =
    donateToRsvpEnabled && isPromptForDonationEvent(event)
  const isDonationCampaign = isDonationCampaignEvent(event)

  return {
    isAdvocacy: isAdvocacyEvent(event),
    isPetition: isPetitionEvent(event),
    isCommitmentGathering: isCommitmentGatheringEvent(event),
    isRegistrationOnly: isRegistrationOnlyEvent(event),
    isGroup: isGroupEvent(event),
    isDonateToRsvp,
    isDonateToRsvpOptional,
    isPromptForDonation,
    isDonationCampaign,
    hasFundraiserForm: isDonationCampaign || isPromptForDonation,
    isVirtualFlexibleEventForDashboardSignups: isVirtualFlexibleEventThatAllowDashboardSignups(
      event
    ),
  }
}

export function getEventImageAltText(event) {
  const intl = createIntl()
  return intl.formatMessage(messages.eventImgAltText, {
    eventName: event.name,
    orgName: event.organization.name,
  })
}

export function parseEventType(maybeEventType) {
  return parseEventTypes([maybeEventType])[0]
}

export function parseEventTypes(maybeEventTypes) {
  return (
    maybeEventTypes
      .map(
        (et) =>
          (typeof et === 'string' && EventType[et.toUpperCase()]) ||
          // $FlowFixMe: flow doesn't know that EventTypes are underlying numbers
          Number(et) ||
          // $FlowFixMe: flow doesn't know that EventTypes are underlying numbers
          0 // gets filtered out, make it a number for flow appeasement
      )
      // $FlowFixMe: flow doesn't know that EventTypes are underlying numbers
      .filter((et) => EventTypeValues.has(et))
  )
}

export function hasRequiredCustomField(event) {
  if (!orgFlagIsActive(event.organization, 'enable_required_custom_fields')) {
    return false
  }

  if (!event.custom_signup_fields) {
    return false
  }

  return event.custom_signup_fields.some((field) => field.is_required)
}

const EVENT_URL_REGEX = /[/]event[/](\d+)/

// Try to pull a numeric event id out of a url; return null otherwise
export const getEventIdFromUrl = (url) => {
  const validIdMatch = url.match(EVENT_URL_REGEX)
  if (validIdMatch && typeof validIdMatch[1] === 'string') {
    return parseInt(validIdMatch[1], 10)
  }
  return null
}

// getSupporterGoalToDisplay and getDonationGoalToDisplay are basically the same
// function, with the same incrementing logic, but I've kept them separate for
// ease of use and clarity of units (participants vs donation dollars vs donation cents)
export function getSupporterGoalToDisplay(event) {
  const customSupporterGoal = event.participant_goal
  if (customSupporterGoal) {
    return customSupporterGoal
  }

  const totalSupporters = event.total_participant_count || 0
  let goalIncrement
  if (totalSupporters < 1000) {
    goalIncrement = 100
  } else if (totalSupporters < 10000) {
    goalIncrement = 500
  } else {
    goalIncrement = 5000
  }
  return Math.ceil((totalSupporters + 1) / goalIncrement) * goalIncrement
}

export function getDonationGoalToDisplayInCents(event) {
  const customDonationGoal = event.participant_goal
    ? event.participant_goal * 100
    : event.fundraising_goal_amount_in_cents
  if (customDonationGoal) {
    return customDonationGoal
  }

  const totalDonationAmountInDollars = event.fundraiser_total_raised_in_cents
    ? centsToDollars(event.fundraiser_total_raised_in_cents)
    : 0
  let goalIncrement
  if (totalDonationAmountInDollars < 1000) {
    goalIncrement = 100
  } else if (totalDonationAmountInDollars < 10000) {
    goalIncrement = 500
  } else {
    goalIncrement = 5000
  }
  return (
    Math.ceil((totalDonationAmountInDollars + 1) / goalIncrement) *
    goalIncrement *
    100
  )
}

export function displaySelfCheckInSetting(event, organization) {
  if (!orgFlagIsActive(organization, 'enable_self_check_in')) {
    return false
  }

  if (event.is_virtual_flexible) {
    return false
  }

  const {isDonationCampaign, isPromptForDonation} = getEventTypeAffinityChecks(
    event,
    organization
  )

  return !isDonationCampaign && !isPromptForDonation
}

function isEventEligibleForOneTapSignup(event) {
  // Events are eligible for one-tap signup if they:
  return (
    // Have no required custom fields, and
    !hasRequiredCustomField(event) &&
    // does not prompt for donation, and
    !isPromptForDonationEvent(event) &&
    // are either shifted, or
    (!event.is_virtual_flexible ||
      // are in a subset of virtual flexible event types where one-tap signup makes sense
      VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP.has(event.event_type))
  )
}

export function canUseOneTapSignup(event, signupIdentityFields) {
  return (
    isEveryIdentityFieldFilledOut(signupIdentityFields) &&
    isEventEligibleForOneTapSignup(event) &&
    !flag.isActive('disable_one_tap_signup')
  )
}
